import {
  CognitoUserPool,
  CognitoUser,
  CognitoAuth,
} from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: 'us-east-2_OTkzWl1bA',
  ClientId: '35trpnvu7o68gu6lm01ad3hepb',
};

export default new CognitoUserPool(poolData);
