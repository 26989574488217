// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-edit-js": () => import("./../../../src/pages/edit.js" /* webpackChunkName: "component---src-pages-edit-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-js": () => import("./../../../src/pages/list.js" /* webpackChunkName: "component---src-pages-list-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-pro-js": () => import("./../../../src/pages/pro.js" /* webpackChunkName: "component---src-pages-pro-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/signIn.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

