/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { User } from './src/UserContext';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 7000,
  offset: '80px',
  transition: transitions.SCALE,
};

export const wrapRootElement = ({ element }) => (
  <AlertProvider template={AlertTemplate} {...options}>
    <User>{element}</User>
  </AlertProvider>
);
