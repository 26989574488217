import React, { createContext } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from './UserPool';

const UserContext = createContext();

const User = (props) => {
  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      if (window) {
        window.location.reload();
      }
    }
  };

  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            const token = session.getIdToken().getJwtToken();
            resolve({ ...session, headers: { Authorization: token } });
          }
        });
      } else {
        reject();
      }
    });

  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data);
        },

        onFailure: (err) => {
          reject(err);
        },

        newPasswordRequired: (data) => {
          resolve(data);
        },
      });
    });

  return (
    <UserContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { User, UserContext };
